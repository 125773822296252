import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { Carousel } from "../Carousel";
import { useGlobalSnackBar } from "../../pages/SnackbarContext";
import { Option } from "../../utils/option";

import "./styles.css";

const useStyles = makeStyles(() => ({
  firstContent: {
    textAlign: "center",
  },
  CardCategoryContent: {
    width: "35%",
    textWrap: "nowrap",
  },
  root: {
    display: "flex",
    gap: "10px",
    position: "relative",
  },
  paginationContent: {
    display: "flex",
    gap: "20px",
    position: "relative",
    justifyContent: "center",
  },
  addIconBtn: {
    right: 0,
    top: "10% !important",
    transform: "translateY(-50%) !important",
    backgroundColor: "rgba(255, 255, 255, 0.5) !important",
    color: "black !important",
    zIndex: 100,
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.7) !important",
    },
    "&": {
      position: "absolute !important",
      backgroundColor: "transparent !important",
    },
  },
  disableColor: {
    color: "rgba(0, 0, 0, 0.26) !important",
  },
}));

const generateFullUncompatibilityOptionList = (data: Option[]) => {
  return data.map((item: Option) => {
    const uncompatibleIds = item.uncompatible_option_ids
      ? JSON.parse(item.uncompatible_option_ids)
      : [];
    data.forEach((otherItem) => {
      const otherItemUncompatibleIds = otherItem.uncompatible_option_ids
        ? JSON.parse(otherItem.uncompatible_option_ids)
        : [];
      if (
        otherItem.ID !== item.ID &&
        otherItemUncompatibleIds.includes(item.ID)
      ) {
        if (!uncompatibleIds.includes(otherItem.ID)) {
          uncompatibleIds.push(otherItem.ID);
        }
      }
    });
    return { ...item, uncompatible_ids: uncompatibleIds };
  });
};

const considerUnCompatibleData = (data: Option[], selectedId: string) => {
  // Clone the data to avoid modifying the original array
  let updatedData = data.map((item) => ({ ...item }));

  // Find the selected item
  let selectedItem = updatedData.find((item) => item.ID === selectedId);
  if (selectedItem) {
    // Toggle isAdded status
    selectedItem.isAdded = !selectedItem.isAdded;
  }

  // Recalculate the disabled status for all items
  updatedData.forEach((item) => {
    item.disabled = false;
  });

  // Find all added items and mark their incompatible items as disabled
  updatedData.forEach((item) => {
    if (item.isAdded) {
      item?.uncompatible_ids?.forEach((uncompatibleId: string) => {
        let uncompatibleItem = updatedData.find(
          (ui) => ui.ID === uncompatibleId
        );
        if (uncompatibleItem) {
          uncompatibleItem.disabled = true;
          return;
        }
      });
    }
  });

  return updatedData;
};

export const CustomizeOptions: React.FC<any> = ({
  options,
  categories,
  setOrderedOptions,
  handleFinish,
  activeQuoteModel,
}) => {
  const classes = useStyles();

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [isSinglePage, setIsSinglePage] = useState<boolean>(false);

  const { showSnackBar } = useGlobalSnackBar();

  const matchIDs = useMemo(() => {
    return activeQuoteModel?.applicable_option_ids
      ? JSON.parse(activeQuoteModel.applicable_option_ids)
      : [];
  }, [activeQuoteModel?.applicable_option_ids]);

  const applicableOptions = useMemo(() => {
    return options?.filter((item: Option) => matchIDs.includes(item.ID));
  }, [options, matchIDs]);

  const totalPages = useMemo(() => {
    return categories?.length;
  }, [categories]);

  const isIncludedItems = useMemo(() => {
    return !categories
      ?.slice(currentPage)
      .flatMap((item: any) => item.items)
      .some((item: Option) => matchIDs.includes(item.ID));
  }, [categories, currentPage, matchIDs]);

  const currentData = useMemo(() => {
    return applicableOptions?.filter(
      (res: Option) => res.parent_id === categories[currentPage - 1].ID
    );
  }, [applicableOptions, currentPage, categories]);

  const generateFullUncompatibilityOptions = useMemo(() => {
    return generateFullUncompatibilityOptionList(applicableOptions);
  }, [applicableOptions]);

  const handlePrev = () => {
    let prevPage = currentPage - 1;
    while (
      prevPage > 0 &&
      !applicableOptions?.some(
        (option: Option) => option.parent_id === categories[prevPage - 1].ID
      )
    ) {
      prevPage--;
    }

    if (prevPage > 0) {
      setCurrentPage(prevPage);
    }
  };

  const handleNext = () => {
    let nextPage = currentPage + 1;
    while (
      nextPage <= totalPages &&
      !applicableOptions?.some(
        (option: Option) => option.parent_id === categories[nextPage - 1].ID
      )
    ) {
      nextPage++;
    }

    if (nextPage <= totalPages) {
      setCurrentPage(nextPage);
    } else {
      showSnackBar(`Added options.`);
      handleFinish();
    }
  };

  useEffect(() => {
    let initialPage = 1;
    let nonEmptyCount = 0;

    while (
      initialPage <= totalPages &&
      !applicableOptions?.some(
        (option: Option) => option.parent_id === categories[initialPage - 1].ID
      )
    ) {
      initialPage++;
    }

    if (initialPage <= totalPages) {
      setCurrentPage(initialPage);
    }

    // Count non-empty categories
    categories.forEach((_: any, index: number) => {
      if (
        applicableOptions?.some(
          (option: Option) => option.parent_id === categories[index].ID
        )
      ) {
        nonEmptyCount++;
      }
    });

    // If only one non-empty category, set isSinglePage to true
    setIsSinglePage(nonEmptyCount === 1);
  }, [totalPages]);

  const handleChoseOption = (option: any, isAdded: boolean) => {
    const updatedData = considerUnCompatibleData(
      generateFullUncompatibilityOptions,
      option.ID
    );

    if (isAdded) {
      showSnackBar(`Added ${option?.option_name}.`);
    } else {
      showSnackBar(`Removed ${option?.option_name}.`, "error");
    }
    setOrderedOptions(updatedData);
  };

  const getCategoryName = useMemo(() => {
    return categories[currentPage - 1]?.option_name;
  }, [categories, currentPage]);

  return (
    <>
      {applicableOptions?.length ? (
        <>
          <Typography variant="h4" noWrap align="center" sx={{ mb: 2, mt: 5 }}>
            Catergory - {getCategoryName}
          </Typography>

          <Grid container spacing={2}>
            {currentData && currentData.length ? (
              currentData?.map((option: any) => (
                <Grid item xs={6} sm={6} md={6} key={option.ID} sx={{ mb: 3 }}>
                  <Card className={classes.root}>
                    <CardContent
                      className={`${classes.firstContent} ${classes.CardCategoryContent}`}
                    >
                      <Typography gutterBottom variant="body1" component="div">
                        {option?.option_name}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        MSRP: {option.MSRP}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Dealer Price: {option.dealer_price}
                      </Typography>
                      <Typography variant="body1" sx={{ mt: 1 }}>
                        Dimensions
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Height: {option.demention_height}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Width: {option.demention_width}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Depth: {option.demention_depth}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Rack Spaces: {option.demention_rack_spaces}
                      </Typography>
                    </CardContent>
                    <Carousel
                      images={
                        option.images
                          ? // ? JSON.parse(option.images)?.filter(
                            //     (item: any) => item !== null
                            //   )
                            JSON.parse(option.images)?.[0]
                            ? [JSON.parse(option.images)?.[0]]
                            : []
                          : []
                      }
                    />
                    <IconButton
                      className={`${classes.addIconBtn} ${
                        option?.disabled && classes.disableColor
                      } `}
                      disabled={option?.disabled}
                    >
                      {option?.isAdded ? (
                        <Tooltip title="Remove Option">
                          <DeleteIcon
                            onClick={() => handleChoseOption(option, false)}
                            className="bright-dark-animation"
                          />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Add Option">
                          <AddCircleIcon
                            onClick={() => handleChoseOption(option, true)}
                            className={
                              !option?.disabled ? "bright-dark-animation" : ""
                            }
                          />
                        </Tooltip>
                      )}
                    </IconButton>
                  </Card>
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sm={12} md={12} sx={{ mb: 3 }}>
                <Typography variant="h5" noWrap align="center">
                  <i>Empty Category</i>
                </Typography>
              </Grid>
            )}
          </Grid>
          <div className={classes.paginationContent}>
            <Button
              variant="contained"
              onClick={handlePrev}
              disabled={currentPage === 1}
            >
              Prev
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={handleNext}
              disabled={!categories?.length}
            >
              {isSinglePage || currentPage === totalPages || isIncludedItems
                ? "Finish"
                : "Next"}
            </Button>
          </div>
        </>
      ) : (
        <>
          <Typography variant="h4" noWrap align="center" sx={{ mb: 2, mt: 5 }}>
            <i>No Available Option</i>
          </Typography>

          <div className={classes.paginationContent}>
            <Button variant="contained" color="primary" onClick={handleNext}>
              Finish
            </Button>
          </div>
        </>
      )}
    </>
  );
};
