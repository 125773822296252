import React, { useEffect, useRef, useState } from "react";
import { AddCircleOutline } from "@mui/icons-material";
import { Chip, Select, MenuItem, FormControl, InputLabel } from "@mui/material";

interface Tag {
  ID: string;
  option_name: string;
  model_name: string;
  email: string;
}

interface TagProps {
  tags: string[];
  setTags: any;
  availableTags: Tag[];
  disabled?: boolean;
  currentID?: string;
}

const checkDisableOptions = (tags: string[], tag: Tag, currentID?: string) => {
  if (tags.filter((res: string) => res === tag.ID)?.length) return true;
  if (currentID && tag.ID === currentID) return true;
  return false;
};

export const SelectTag: React.FC<TagProps> = ({
  tags,
  setTags,
  availableTags,
  disabled,
  currentID,
}) => {
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [editInputIndex, setEditInputIndex] = useState(-1);
  const [editInputValue, setEditInputValue] = useState("");

  const inputRef = useRef<HTMLDivElement>(null);
  const editInputRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inputVisible && inputRef.current) {
      inputRef.current.focus();
    }
  }, [inputVisible]);

  useEffect(() => {
    if (editInputValue && editInputRef.current) {
      editInputRef.current.focus();
    }
  }, [editInputValue]);

  const handleClose = (removedTag: string) => {
    const newTags = tags.filter((tag: string) => tag !== removedTag);
    setTags(newTags);
  };

  const showInput = () => {
    setInputVisible(true);
  };

  const handleSelectChange = (e: { target: { value: string } }) => {
    setInputValue(e.target.value);
  };

  const handleSelectConfirm = () => {
    if (inputValue && !tags.includes(inputValue)) {
      setTags([...tags, inputValue]);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleEditSelectChange = (e: { target: { value: string } }) => {
    setEditInputValue(e.target.value);
  };

  const handleEditSelectConfirm = () => {
    const newTags = [...tags];
    newTags[editInputIndex] = editInputValue;
    setTags(newTags);
    setEditInputIndex(-1);
    setEditInputValue("");
  };
  return (
    <>
      {tags.map((tagID: string, index: number) => {
        const tag = availableTags.find((t) => t.ID === tagID);
        if (!tag) return null;

        if (editInputIndex === index) {
          return (
            <FormControl key={tag.ID} variant="outlined" sx={{ mr: 1 }}>
              <Select
                value={editInputValue}
                onChange={handleEditSelectChange}
                onBlur={handleEditSelectConfirm}
                autoWidth
                ref={editInputRef}
                size="small"
              >
                {availableTags.map((tag) => (
                  <MenuItem
                    key={tag.ID}
                    value={tag.ID}
                    disabled={checkDisableOptions(tags, tag, currentID)}
                  >
                    {tag.option_name || tag.model_name || tag.email}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          );
        }
        return (
          <Chip
            key={tag.ID}
            label={tag.option_name || tag.model_name || tag.email}
            onDelete={() => handleClose(tag.ID)}
            onClick={() => {
              setEditInputIndex(index);
              setEditInputValue(tag.ID);
            }}
            sx={{ mr: 1 }}
            disabled={disabled}
          />
        );
      })}
      {inputVisible ? (
        <FormControl variant="outlined" ref={inputRef}>
          <InputLabel size="small">Add Tag</InputLabel>
          <Select
            value={inputValue}
            onChange={handleSelectChange}
            onBlur={handleSelectConfirm}
            autoWidth
            label="Add Tag"
            size="small"
            sx={{ width: 200 }}
          >
            {availableTags.map((tag) => (
              <MenuItem
                key={tag.ID}
                value={tag.ID}
                disabled={checkDisableOptions(tags, tag, currentID)}
              >
                {tag?.option_name || tag?.model_name || tag?.email}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ) : (
        !disabled && (
          <Chip icon={<AddCircleOutline />} label="Add +" onClick={showInput} />
        )
      )}
    </>
  );
};
