import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, Chip, Grid, Typography } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";

import { useOptionData } from "../../../hook/useOptionData";
import { getUserModelByID } from "../../../apis/model";
import { useGlobalSnackBar } from "../../SnackbarContext";
import {
  Option,
  filteredChipOptions,
  getHoverImage,
} from "../../../utils/option";
import { Loader } from "../../../components/Loader";

import "./styles.css";

const useStyles = makeStyles(() => ({
  hoveredImageWrapper: {
    position: "absolute",
    zIndex: 10000,
  },
  chipWrapper: {
    marginRight: "8px",
    cursor: "pointer",
  },
  hoveredImage: {
    width: "200px" /* Adjust the size as needed */,
    height: "100px",
    transition: "opacity 0.3s ease-in-out",
    opacity: 1,
    objectFit: "cover",
  },
  hidden: {
    opacity: 0,
  },
}));

export const UserModelsView: React.FC = () => {
  const classes = useStyles();

  const navigate = useNavigate();

  const { ID } = useParams();

  const { options } = useOptionData("dealer");
  const [hoveredOption, setHoveredOption] = useState<Option | null>(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (
    option: Option,
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setMousePosition({ x: event.pageX, y: event.pageY });
    setHoveredOption(option);
  };

  const handleMouseLeave = () => {
    setHoveredOption(null);
  };

  const handleMouseMove = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    setMousePosition({ x: event.pageX, y: event.pageY });
  };

  const { showSnackBar } = useGlobalSnackBar();

  const [model, setModel] = useState<any>(null);
  const [logos, setLogos] = useState<Array<string | ArrayBuffer | null>>([
    null,
    null,
    null,
  ]);
  const [isLoading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchModelData = async () => {
      try {
        const modelData = await getUserModelByID({ ID });
        setModel({ ...modelData });
        setLogos(modelData.images ? JSON.parse(modelData.images) : []);

        setLoading(false);
      } catch (error) {
        showSnackBar("Failed to fetch model", "error");
      }
    };

    if (ID) {
      fetchModelData();
    } else {
      setLoading(false);
    }
  }, [ID, showSnackBar]);

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Typography variant="h4" align="center">
        {model?.model_name}
      </Typography>
      <Typography variant="h6" align="center">
        Part # {model?.part_number}
      </Typography>

      <Grid
        container
        spacing={2}
        mt={3}
        pl={{ lg: 20, md: 2, xs: 2 }}
        pr={{ lg: 20, md: 2, xs: 2 }}
      >
        {logos?.map((logo, index) => (
          <Grid item xs={3} key={index}>
            <Avatar
              sx={{ width: "100%", height: 300 }}
              variant="square"
              src={
                typeof logo === "string"
                  ? `${process.env.REACT_APP_COMPANY_LOGO_URL}/${logo}`
                  : undefined
              }
              className="uploadImage"
            />
          </Grid>
        ))}
        <Grid xs={3} mt={0}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
              mt: 0.5,
            }}
          >
            <Typography sx={{ width: "80%" }} align="right">
              MSRP:
            </Typography>
            <span style={{ width: 100 }}>{model?.MSRP}</span>
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
              mt: 0.5,
            }}
          >
            <Typography sx={{ width: "80%" }} align="right">
              Dealer Price:
            </Typography>
            <span style={{ width: 100 }}>{model?.dealer_price}</span>
          </Typography>
          <Typography variant="h5" align="center" mt={2.5} mb={1.5}>
            Dimensions
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
              mt: 0.5,
            }}
          >
            <Typography sx={{ width: "80%" }} align="right">
              Height:
            </Typography>
            <span style={{ width: 100 }}>{model?.demention_height}</span>
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
              mt: 0.5,
            }}
          >
            <Typography sx={{ width: "80%" }} align="right">
              Width:
            </Typography>
            <span style={{ width: 100 }}>{model?.demention_width}</span>
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
              mt: 0.5,
            }}
          >
            <Typography sx={{ width: "80%" }} align="right">
              Depth:
            </Typography>
            <span style={{ width: 100 }}>{model?.demention_depth}</span>
          </Typography>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 1,
              justifyContent: "center",
              mt: 0.5,
            }}
          >
            <Typography sx={{ width: "80%" }} align="right">
              Rack Spaces:
            </Typography>
            <span style={{ width: 100 }}>{model?.demention_rack_spaces}</span>
          </Typography>
        </Grid>
      </Grid>

      <Grid
        container
        spacing={2}
        mt={3}
        pl={{ lg: 20, md: 2, xs: 2 }}
        pr={{ lg: 20, md: 2, xs: 2 }}
      >
        {/* <Grid item xs={12}>
          <Typography variant="h5" mt={2.5} mb={1.5}>
            Applicable Options:
          </Typography>
          <Typography
            variant="h6"
            sx={{
              mt: 2.5,
              mb: 1.5,
              gap: 1,
            }}
          >
            {filteredChipOptions(
              options,
              JSON.parse(model?.applicable_option_ids)
            )?.map((res) => (
              <Chip
                key={res?.ID}
                label={res?.option_name}
                variant="outlined"
                onMouseEnter={(e) => handleMouseEnter(res, e)}
                onMouseLeave={handleMouseLeave}
                className={classes.chipWrapper}
                onMouseMove={handleMouseMove}
              />
            ))}
            {hoveredOption && (
              <Box
                sx={{
                  top: mousePosition.y + 10, // Offset by 10 pixels to avoid cursor overlap
                  left: mousePosition.x + 10,
                  pointerEvents: "none", // Ensures the image does not capture mouse events
                }}
                className={classes.hoveredImageWrapper}
              >
                {JSON.parse(hoveredOption.images)?.[0] ? (
                  <img
                    src={getHoverImage(hoveredOption.images) || ""}
                    className={`${classes.hoveredImage} ${
                      hoveredOption ? "" : classes.hidden
                    }`}
                  />
                ) : (
                  <i>No images</i>
                )}
              </Box>
            )}
          </Typography>
        </Grid> */}
      </Grid>

      <Grid
        container
        spacing={2}
        pl={{ lg: 20, md: 2, xs: 2 }}
        pr={{ lg: 20, md: 2, xs: 2 }}
      >
        <Grid item xs={12}>
          <Typography variant="h5" mt={2.5} mb={1.5}>
            Description:
          </Typography>
          <Typography variant="h6" mt={2.5} mb={1.5}>
            {model?.description}
          </Typography>

          <Typography align="center" mt={4.5} mb={1.5}>
            <Button
              variant="contained"
              onClick={() => navigate(`/_models/${ID}/customize`)}
            >
              Add & Customize
            </Button>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};
