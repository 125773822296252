import React, { ReactNode, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Grid,
  IconButton,
  ListItem,
  Tooltip,
  Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import DeleteIcon from "@mui/icons-material/Delete";

import DefaultTable from "../../../components/Table/DefaultTable";
import { deleteOption, getSearchOptions } from "../../../apis/option";
import { useGlobalSnackBar } from "../../SnackbarContext";
import { CustomDialog } from "../../../components/modal/CustomDialog";
import { useModelData } from "../../../hook/useModelData";
import { filteredModels } from "../../../utils/model";
import { Option } from "../../../utils/option";
import { Loader } from "../../../components/Loader";
import { usePermissionHook } from "../../../hook/usePermissionHook";

interface TRowData<T> {
  [key: string]: T;
}

const headerData: string[] = [
  "Part #",
  "Option",
  "Category",
  "Applicable Modles",
  "MSRP",
  "",
];

const sortableFields: any = {
  "Part #": "part_number",
  Option: "option_name",
  Category: "parent_id",
  MSRP: "MSRP",
};

interface StatCardProps {
  value: number | string;
  description: string;
}

const StatCard: React.FC<StatCardProps> = ({ value, description }) => (
  <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
    <Card sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}>
      <CardContent sx={{ pb: "10px !important" }}>
        <Typography variant="h5" component="div">
          {value}
        </Typography>
        <Typography sx={{ mb: 1.5 }} color="text.secondary">
          {description}
        </Typography>
      </CardContent>
    </Card>
  </Grid>
);

export const Options: React.FC = () => {
  const history = useNavigate();
  const { showSnackBar } = useGlobalSnackBar();

  const [options, setOptions] = useState<TRowData<ReactNode>[]>([]);
  const [openDeleteModal, setOpenDeleteModal] = useState<boolean>(false);
  const [optionId, setOptionId] = useState<string | null>(null);
  const [categories, setCategories] = useState<any | []>([]);
  const [isLoading, setIsLoading] = useState<boolean | false>(true);
  const [page, setPage] = useState<number>(0);
  const [pageSize, setPageSize] = useState<number>(10);
  const [count, setCount] = useState<number>(0);

  const { models } = useModelData();
  const { permissionReadOnly } = usePermissionHook();

  const [orderBy, setOrderBy] = useState<string | null>(null);
  const [order, setOrder] = useState<"asc" | "desc">("asc");

  const fetchOptions = async () => {
    try {
      const {
        data: optionData,
        counts,
        categoryData,
      } = await getSearchOptions({
        page: page + 1,
        pageSize,
        orderBy: orderBy && sortableFields[orderBy],
        order,
      });

      setCategories(categoryData);
      const transformedData = optionData.map((option: Option) => ({
        part: (
          <ListItem
            disablePadding
            component={Link}
            to={`/options/${option.ID}`}
          >
            {option.part_number}
          </ListItem>
        ),
        option: option?.option_name,
        category: categoryData.find(
          (category: any) => category.ID === option.parent_id
        )?.option_name,
        applicable_modles: filteredModels(
          models,
          JSON.parse(option.applicable_model_ids)
        ),
        msrp: option.MSRP,
        edit: (
          <div>
            <Tooltip title="Edit">
              <IconButton onClick={() => history(`/options/${option.ID}`)}>
                <EditCalendarIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton
                onClick={() => {
                  setOptionId(option.ID);
                  setOpenDeleteModal(true);
                }}
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>
          </div>
        ),
      }));
      setOptions(transformedData);
      setCount(counts);
      setIsLoading(false);
    } catch (error) {
      console.error("Failed to fetch admins", error);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchOptions();
  }, [models, page, pageSize, order, orderBy]);

  // Function to handle sorting
  const handleSort = (column: string) => {
    if (!sortableFields[column]) return;
    const isAsc = orderBy === column && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(column);
  };

  const permissionErrorMessage = () => {
    showSnackBar("Not allowed permission!", "error");
  };

  const onDelete = async () => {
    if (permissionReadOnly) {
      permissionErrorMessage();
      return;
    }
    try {
      await deleteOption({ ID: optionId || "" });
      showSnackBar("Delete this option");
      fetchOptions();
      setOpenDeleteModal(false);
    } catch (err) {
      showSnackBar("Error", "error");
    }
  };

  const handleChangePage = (page: number) => {
    setPage(page);
  };

  const handleChangeRowsPerPage = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setPageSize(Number(e.target.value));
  };

  if (isLoading) return <Loader isLoading={isLoading} />;

  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={2}>
          <StatCard value={count} description="Options" />
          <StatCard value={categories?.length} description="Categories" />
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => {
                if (permissionReadOnly) {
                  permissionErrorMessage();
                  return;
                }
                history("/options/create");
              }}
              sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}
            >
              <CardContent sx={{ pb: "10px !important" }}>
                <Typography variant="h5" component="div">
                  +
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Add Options
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={3} sx={{ display: "flex", justifyContent: "center" }}>
            <Card
              style={{ textAlign: "center", cursor: "pointer" }}
              onClick={() => history("/options/categories")}
              sx={{ width: "60%", minWidth: "180px", textAlign: "center" }}
            >
              <CardContent sx={{ pb: "10px !important" }}>
                <Typography variant="h5" component="div">
                  +
                </Typography>
                <Typography sx={{ mb: 1.5 }} color="text.secondary">
                  Manage Category
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <Box sx={{ flexGrow: 1, mt: 2 }}>
        <DefaultTable
          headerData={headerData}
          rowsData={options}
          rowsPerPage={pageSize}
          page={page}
          count={count}
          handleChangePage={(
            e: React.ChangeEvent<HTMLInputElement>,
            page: number
          ) => handleChangePage(page)}
          handleChangeRowsPerPage={(e: React.ChangeEvent<HTMLInputElement>) =>
            handleChangeRowsPerPage(e)
          }
          handleSort={handleSort}
          order={order}
          orderBy={orderBy}
          sortableFields={sortableFields}
        />
      </Box>

      <CustomDialog
        title="Are you sure you want to delete this option?"
        openModal={openDeleteModal}
        handleClose={() => setOpenDeleteModal(false)}
        handleAction={onDelete}
        buttonTitle={"Yes"}
        children={undefined}
      />
    </>
  );
};
