import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { Button } from "@mui/material";

import { resetPassword, verifyToken } from "../../../apis/dealer";
import { UserAuthService } from "../../../services/UserAuthService";
import { Input } from "../../../components/Input";

const SUCCESS_MSG = "Token is valid. Welcome!";

export const DealerForgotPasswordPage = () => {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState("Checking...");
  const [email, setEmail] = useState<string | null>(null);
  const [password, setPassword] = useState<string | null>(null);
  const [confirmPassword, setConfirmPassword] = useState<string | null>(null);
  const token = searchParams.get("token");
  const dispatch = useDispatch();

  useEffect(() => {
    const verify = async () => {
      try {
        const res = await verifyToken(String(token) || "");
        if (res?.success) {
          setStatus(SUCCESS_MSG);
          setEmail(res.email);
        } else {
          setStatus(res.message);
        }
      } catch (error) {
        setStatus("Invalid or expired token.");
      }
    };

    if (token) {
      verify();
    } else {
      setStatus("No token found in the URL.");
    }
  }, [token]);

  const handleSubmit = async () => {
    try {
      await UserAuthService.login(email || "", password || "123", dispatch);
      window.location.href = "/";
    } catch (error: any) {
      console.error(error);
    }
  };

  const resetPass = async () => {
    if (password !== confirmPassword) {
      alert("Passwords do not match. Please try again.");
      return;
    }

    try {
      await resetPassword({ password, email });
      alert("Password Set!");
      handleSubmit()
    } catch (err) {
      console.error(err);
      alert(`Failed: ${err}`);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
        textAlign: "center",
      }}
    >
      <h1>Reset Password</h1>
      {status === SUCCESS_MSG ? (
        <>
          <Input
            label="Password:"
            size="small"
            width={340}
            type="password"
            onChange={(e) => setPassword(e.target.value)}
          />
          <Input
            label="Confirm Password:"
            size="small"
            width={340}
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            sx={{ mt: 2 }}
          />
          <Button variant="contained" sx={{ mt: 2 }} onClick={resetPass}>
            Reset
          </Button>
        </>
      ) : (
        <>{status}</>
      )}
    </div>
  );
};
